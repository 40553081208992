.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-family: "Prata", serif;
  background-color: #fff;
  padding: 2vw;
}

nav ul {
  display: flex;
  list-style-type: none;
  margin: 0;
  padding: 0;
}

nav ul li {
  margin-right: 1vw;
}

.language-buttons,
.nav-links {
  display: flex;
}

.nav-links a {
  text-decoration: none;
  color: #555; /* Couleur de texte grise */
  font-family: "Prata", serif;
  font-size: 16px;
  margin-right: 20px;
  padding: 5px 10px;
  transition: color 0.3s ease; /* Transition de couleur de texte */
}

.nav-links a:hover {
  color: #007bff; /* Couleur de texte bleue au survol */
}

.language-buttons a {
  text-decoration: none;
  color: inherit;
  font-family: "Prata", serif;
  font-size: 16px;
  margin-right: 10px;
  padding: 5px 10px;
  border: 1px solid transparent;
  border-radius: 5px;
  transition: background-color 0.3s ease;
}

.language-buttons a:hover {
  background-color: #f0f0f0;
}

.burger-menu {
  display: none;
}

.mobile-logo {
  display: none;
}

@media screen and (max-width: 768px) {
  .header {
    flex-direction: column;
    align-items: center;
  }

  nav ul {
    flex-direction: column;
    align-items: center;
  }

  nav ul li {
    margin: 20px 0;
  }

  .burger-menu {
    display: block;
    cursor: pointer;
  }

  .mobile-logo,
  .logo {
    display: block;
    margin-top: 50px;
    text-align: center;
  }
}
